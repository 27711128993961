import { Seller } from "../seller/seller.model";

export class AddUserDetailCommand{
  mail: string;
  firstName: string;
  lastName: string;
  phone: string;
  document: string;
  online: boolean;


  constructor(seller: Seller){
      this.mail = seller.mail;
      this.firstName = seller.firstName;
      this.lastName = seller.lastName;
      this.phone = seller.phone;
      this.document = seller.document;
      this.online = seller.online;
  }
}