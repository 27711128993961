import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HasWrongEmailComponent} from './has-wrong-email.component';
import {HasWrongEmailRoutingModule} from './has-wrong-email-routing.module';


@NgModule({
  imports: [
    CommonModule,
    HasWrongEmailRoutingModule
  ],
  declarations: [HasWrongEmailComponent]
})
export class HasWrongEmailModule { }
