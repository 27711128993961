import { AddUserDetailCommand } from "./add-user-detail-command";

export class AddUserCommand{
    raffleId: string;
    users: Array<AddUserDetailCommand> = [];

    constructor(raffleId: string, users: Array<AddUserDetailCommand>){
        this.raffleId = raffleId;
        this.users = users;
    }


}