import { NgModule, Type } from '@angular/core';
import { Routes, RouterModule, ROUTES } from '@angular/router';
import { CanActivateWrongMail } from './guards/can-activate-wrong-mail';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'signin',
    loadChildren: () => import('./components/signin/signin.module').then(m => m.SigninModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./components/signup/signup.module').then(m => m.SignupModule)

  },
  {
    path: 'contact',
    loadChildren: () => import('./components/contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'registrationSuccess',
    loadChildren: () => import('./components/registration-success/registration-success.module').then(m => m.RegistrationSuccessModule)
  },
  {
    path: 'confirmUser',
    loadChildren: () => import('./components/user-registration-verification/user-registration-verification.module').then(m => m.UserRegistrationVerificationModule)
  },
  {
    path: 'faqs',
    loadChildren: () => import('./components/faqs/faqs.module').then(m => m.FaqsModule)
  },
  {
    path: 'tickets',
    loadChildren: () => import('./components/tickets/tickets.module').then(m => m.TicketsModule),
    canActivate: [CanActivateWrongMail]
  },
  {
    path: 'raffledetail',
    loadChildren: () => import('./components/raffledetail/raffledetail.module').then(m => m.RaffleDetailModule)
  },
  {
    path: 'participations',
    loadChildren: () => import('./components/participations/participations.module').then(m => m.ParticipationsModule),
    canActivate: [CanActivateWrongMail]
  },
  {
    path: 'buy',
    loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule),
    canActivate: [CanActivateWrongMail]
  },
  {
    path: 'auth',
    loadChildren: () => import('./components/oauth-callback/oauth-callback.module').then(m => m.OauthCallbackModule)
  },
  {
    path: 'howitwork',
    loadChildren: () => import('./components/howitwork/howitwork.module').then(m => m.HowItWorkModule)
  },
  {
    path: 'aboutus',
    loadChildren: () => import('./components/about-us/about-us.module').then(m => m.AboutUsModule)
  },

  {
    path: 'raffle',
    loadChildren: () => import('./components/raffle-container/raffle-container.module').then(m => m.RaffleContainerModule),
    canActivate: [CanActivateWrongMail]
  },
  {
    path: 'userParticipation',
    loadChildren: () => import('./components/user-participation/user-participation.module').then(m => m.UserParticipationModule),
    canActivate: [CanActivateWrongMail]
  },
  {
    path: 'mpseller',
    loadChildren: () => import('./components/mp-seller-callback/mp-seller-callback.module').then(m => m.MpSellerCallbackModule),
    canActivate: [CanActivateWrongMail]
  },
  {
    path: 'forgotPassword',
    loadChildren: () => import('./components/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'forgotPasswordSuccess',
    loadChildren: () => import('./components/forgot-password-success/forgot-password-success.module').then(m => m.ForgotPasswordSuccessModule)
  },
  {
    path: 'userNotification/unsubscribe',
    loadChildren: () => import('./components/user-notification/user-notification.module').then(m => m.UserNotificationModule),
    canActivate: [CanActivateWrongMail]
  },
  {
    path: 'soldTickets',
    loadChildren: () => import('./components/sold-ticket/sold-ticket.module').then(m => m.SoldTicketModule),
    canActivate: [CanActivateWrongMail]
  },
  {
    path: 'editUserProfile',
    loadChildren: () => import('./components/edit-user-profile/edit-user-profile.module').then(m => m.EditUserProfileModule)
  },
  {
    path: 'changePassword',
    loadChildren: () => import('./components/change-password/change-password.module').then(m => m.ChangePasswordModule)
  },
  {
    path: 'editUserProfileSuccess',
    loadChildren: () => import('./components/edit-user-profile-success/edit-user-profile-success.module').then(m => m.EditUserProfileSuccessModule)
  },
  {
    path: 'simulator',
    loadChildren: () => import('./components/simulator/simulator.module').then(m => m.SimulatorModule)
  },
  {
    path: 'raffle-proposal',
    loadChildren: () => import('./components/raffle-proposal/raffle-proposal.module').then(m => m.RaffleProposalModule)
  },
  {
    path: 'campaigns',
    loadChildren: () => import('./components/campaign/campaign.module').then(m => m.CampaignModule),
    canActivate: [CanActivateWrongMail]
  },
  {
    path: 'paymentModes',
    loadChildren: () => import('./components/user-payment-mode/user-payment-mode.module').then(m => m.default),
    canActivate: [CanActivateWrongMail]
  },
  {
    path: 'fastPurchase',
    loadChildren: () => import('./components/fast-purchase/fast-purchase.module').then(m => m.FastPurchaseModule),
    canActivate: [CanActivateWrongMail]
  },
  {
    path: 'raffleUser',
    loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'payment-redirection',
    loadChildren: () => import('./components/payment-redirection/payment-redirection.module').then(m => m.PaymentRedirectionModule)
  },
   {
    path: 'payment-redirection',
    loadChildren: () => import('./components/payment-redirection/payment-redirection.module').then(m => m.PaymentRedirectionModule)
  },
  {
    path: 'pagopar',
    loadChildren: () => import('./components/pagopar-callback/pagopar-callback.module').then(m => m.PagoparCallbackModule)
  },
  { path: '**', 
  loadChildren: () => import('./components/route/larifamos-route.module').then(m => m.LaRifamosRouteModule)}];



@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false })
  ],

  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
