import {Component, OnInit, Inject} from '@angular/core';
import { TermsAndConditionsService } from 'src/app/services/terms-and-conditions-service';
import { LaRifamosErrorHandler } from 'src/app/handler/laRifamosErrorHandler';
import { LaRifamosContext } from 'src/app/context/laRifamosContext';
import { Router } from '@angular/router';


@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {

  
  termsAndConditionsBody: string;
  termsAndConditionTitle: string;
  constructor(private termsAndConditionService: TermsAndConditionsService, private errorHandler: LaRifamosErrorHandler, private context: LaRifamosContext,
    private router: Router) {

  }

  ngOnInit() {
    this.termsAndConditionTitle = this.context.termsAndConditionsTitle();
    if (this.router.url.endsWith('signup') || !this.context.raffleTermsAndConditions){
      this.getTermsAndConditions();
    } else {
      this.termsAndConditionsBody = this.context.raffleTermsAndConditions.body;
    }

  }


  close(): void {
    const ev = new KeyboardEvent('keydown', {
      key: 'Escape'
    });
    const elm = document.getElementById('test')
    elm.addEventListener('keydown', function(e) {
    });
    elm.dispatchEvent(ev);
  }

  getTermsAndConditions(){
    const response = this.termsAndConditionService.getTermnsAndConditions();
    response.subscribe(
      data => {
          this.termsAndConditionsBody = data.termsAndConditionsBody;
      }
      ,
      errorResponse => {
        this.errorHandler.showErrorResponse('Ocurrió un error al obtener términos y condiciones.');
      }
    );
  }

}
