import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FooterInformationService {
  url;

  constructor(private http: HttpClient) {
    this.url = environment.APIEndpoint + '/larifamos-service/footers/' + environment.country;
  }

  getFooterInformation() {
    const companyInformationsURL = this.url;
    return this.http.get(companyInformationsURL);
  }
}
