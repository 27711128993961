import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { LaRifamosContext } from "./context/laRifamosContext";
import { environment } from "src/environments/environment";
import { AuthService } from "./services/auth.service";
import { Injectable, Inject, PLATFORM_ID} from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { Router } from "@angular/router";
import { E } from "@angular/cdk/keycodes";
import { catchError, switchMap } from "rxjs/operators";
import { LaRifamosErrorHandler } from "./handler/laRifamosErrorHandler";


@Injectable()
export class JWTInterceptor implements HttpInterceptor{

    constructor(private authService: AuthService, @Inject(PLATFORM_ID) private platformId: Object, public router: Router, private errorHandler: LaRifamosErrorHandler) { }



    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
      ): Observable<HttpEvent<unknown>> {
        
          try {
            request = request.clone({
              setHeaders: {
                Authorization: this.authService.getLoggedInUserToken(),
              },
            });
          } catch (exception) {}
        
        
        return next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error?.status == 401 || error?.status == 403) {
                if (!this.isTokenExpired(this.authService.getLoggedInUserRefreshToken())) {
                    return this.refreshTokenMethod(request, next);
                } else {
                    this.redirectLogout();
                    return throwError(() => error);
                }
            } else {
              this.errorHandler.fillErrorResponse(error);
              return throwError(() => error);
            }
          })
        );
      }



      refreshTokenMethod(
        request: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {
        return this.authService.refreshToken().pipe(
          switchMap((res: any) => {
            request = request.clone({
              setHeaders: {
                Authorization: this.authService.getLoggedInUserToken(),
              },
            });
            return next.handle(request);
          }),
          catchError((error) => {
            this.redirectLogout();
            return throwError(() => error);
          })
        );
      }


    private isTokenExpired(token: string) {
        if (token == null || token == undefined)
         return true;

        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        return expiry * 1000 < Date.now();
    }


    redirectLogout() {
        this.authService.signout();
        this.router.navigate(['/signin']);
      }
}


