import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LaRifamosContext } from '../context/laRifamosContext';
import { tap } from 'rxjs/operators';
import { ReceiptCancelRequest } from '../components/admin/entities/receipt-cancel-request';


@Injectable({
  providedIn: 'root'
})
export class RaffleAccountManagerService {
 
 

  
  url;

  constructor(private http: HttpClient, private context: LaRifamosContext) {
    this.url = environment.APIEndpoint + '/raffle-service/raffle-account-manager/';
  }


  public findByUserId(userId: string): any {
    return this.http.get(this.url + userId)
      .pipe(tap(
        data => {
        }
      ));
  }

  public findRafflesByAccountManager( userId: string): any {
    return this.http.get(this.url + userId + '/raffles')
      .pipe(tap(
        data => {
        }
      ));
  }

  deleteRaffle(userId: string, raffleId: string) {
    return this.http.delete(this.url + userId + '/raffles/'+raffleId)
      .pipe(tap(
        data => {
        }
      ));
  }

  public findTaskByState(state: string): any {
    return this.http.get(this.url + 'tasks/state/' + state)
      .pipe(tap(
        data => {
        }
      ));
  }

  public findAccountManagerTask(raffleId: string): any {
    return this.http.get(this.url + 'tasks/raffle/' + raffleId)
      .pipe(tap(
        data => {
        }
      ));
  }


  public approve(taskId: string): any {
    return this.http.put(this.url + 'tasks/approve/' + taskId, {})
      .pipe(tap(
        data => {
        }
      ));
  }


  public reject(taskId: string): any {
    return this.http.put(this.url + 'tasks/reject/' + taskId, {})
      .pipe(tap(
        data => {
        }
      ));
  }

  receiptsReport(receiptState: string, raffleId: string, page: number, pageLimit: number): any {
    const reportUrl = this.url + 'receipts/' + receiptState + '/raffle/' + raffleId;
    let queryParams = new HttpParams();
    queryParams = queryParams.append("pageNumber", page);
    queryParams = queryParams.append("pageLimit", pageLimit);
    return this.http.get(reportUrl, {
      params: queryParams
    }).pipe();
  }

  public downloadReceiptReport(receiptState: string, raffleId: string) {
    const reportUrl = this.url + 'receipts/' + receiptState + '/raffle/' + raffleId + '/download';
    return this.http.get(reportUrl, {observe: 'response', responseType: 'blob' });
  }

  clientsReport(raffleId: string, page: number, pageLimit: number): any {
    const reportUrl = this.url + 'clients/raffle/' + raffleId;
    let queryParams = new HttpParams();
    queryParams = queryParams.append("pageNumber", page);
    queryParams = queryParams.append("pageLimit", pageLimit);
    return this.http.get(reportUrl, {
      params: queryParams
    }).pipe();
  }


  clientsReportDownload(raffleId: string) {
    const reportUrl = this.url + 'clients/raffle/' + raffleId + '/download';
    return this.http.get(reportUrl, {observe: 'response', responseType: 'blob' });
  }

  cancelReceipt( cancelRequest: ReceiptCancelRequest) {
    return this.http.post(this.url+'receipt/cancel', {
    'purchaseId': cancelRequest.purchaseId,
    'reason': cancelRequest.reason
  }  )
    .pipe(
      tap(
        data => {
        }
      ));
  }
}