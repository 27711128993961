import {Component, OnInit, Inject} from '@angular/core';

@Component({
  selector: 'app-has-wrong-email',
  templateUrl: './has-wrong-email.component.html',
  styleUrls: ['./has-wrong-email.component.css']
})
export class HasWrongEmailComponent implements OnInit {

  body: string;

  constructor() {
    this.body = 'Hemos detectado que no nos podemos comunicar contigo por correo electrónico. Por favor actualizalo y así podras participar y recibir nuestas notificaciones';
  }

  ngOnInit() {
  }


  close(): void {
    const ev = new KeyboardEvent('keydown', {
      key: 'Escape'
    });
    const elm = document.getElementById('test');
    elm.addEventListener('keydown', function(e) {
    });
    elm.dispatchEvent(ev);
  }

}
