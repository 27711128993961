<header class="header1" >
  <div class="container-menu-header" *ngIf="!isInMobile()">
    <div class="wrap_header">
      <a id="logo-home-button" href="index.html" class="logo">
        <img src="assets/img/larifamos-logo.png" class="img-fluid" alt="La Rifamos logo">
        <img src="assets/img/larifamos-logo-text.png" class="logotext" style="height: 20px;" alt="Responsive image">
      </a>
      <div class="wrap_menu">
        <nav class="menu">
          <ul class="main_menu">
            <li>
              <a id="home-button" routerLink="/">Inicio</a>
            </li>
            <li *ngIf="laRifamosContext.showCampaignsOption">
              <a id="campaigns-button" routerLink="/campaigns">Promociones! <span
                  class="badge badge-danger">Nuevo</span></a>
            </li>
            <li>
              <a id="how-it-work-button" routerLink="/howitwork">¿Cómo funciona?</a>
            </li>
            <li *ngIf="isRaffleSimulatorEnable()">
              <a id="raffle-simulator-button" routerLink="/raffle-proposal">Quiero rifar</a>
            </li>
            <li>
              <a id="faqs-button" routerLink="/faqs">Preguntas frecuentes</a>
            </li>
            <li>
              <a id="about-us-button" routerLink="/aboutus">Sobre nosotros</a>
            </li>
            <li>
              <a id="contact-button" routerLink="/contact">Contacto</a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="header-icons">
        <div class="wrap_menu">
          <nav class="menu">
            <ul *ngIf="!isSigninPage() && !isSignupPage() && !getIsLogged()" class="main_menu">
              <li>
                <a id="signup-button" routerLink="/signup">Crear cuenta</a>
              </li>
              <span class="linedivide1-nav"></span>
              <li>
                <a id="signin-button" routerLink="/signin">Ingresar</a>
              </li>
            </ul>

            
            <div *ngIf="getIsLogged()" class="main_menu">
                <span class="color-white">Hola {{getCurrentUser().firstName}}</span>
                <button id="accountMenu" mat-button [matMenuTriggerFor]="accountMenu">
                  <mat-icon class="account-icon hand color-white">account_circle</mat-icon>
                </button>
                <mat-menu backdropClass="menu-desktop"  #accountMenu="matMenu">
                  <button mat-menu-item id="user-profile-button" class="item-menu-mobile" name="editUserProfile"
                    (click)='redirectToPage($event)'>Mi perfil</button>
                  <button mat-menu-item id="change-password-button" class="item-menu-mobile" name="changePassword"
                    (click)='redirectToPage($event)'>Cambiar contraseña</button>
                  <button mat-menu-item id="user-participation-button" class="item-menu-mobile" name="userParticipation"
                    (click)='redirectToPage($event)'>Mis participaciones</button>
                  <button *ngIf="isUserPaymentModesFeatureEnable()" mat-menu-item id="credit-card-button" class="item-menu-mobile" name="paymentModes"
                    (click)='redirectToPage($event)'>Mis Formas de pago</button>
                  <button mat-menu-item *ngIf="isAnAccountManager()" id="account-manager-button" class="item-menu-mobile"
                    (click)='redirectAsAccountManager()'>Gestor de rifa</button>
                    <button mat-menu-item *ngIf="isASeller()" id="seller-button" class="item-menu-mobile"
                    (click)='redirectAsSeller()'>Vendedor</button>
                  <button mat-menu-item id="logout-button" class="item-menu-mobile" name="logout"
                    (click)='signout()'>Cerrar sesión</button>
                </mat-menu>
              </div>
          </nav>
        </div>

      </div>
    </div>
  </div>


  <div class="wrap_header_mobile">
    <a id="logo-home-button" href="index.html" class="logo-mobile">
      <img src="assets/img/larifamos-logo.png" class="img-fluid" alt="La Rifamos logo">
      <img src="assets/img/larifamos-logo-text.png" class="logotext" style="height: 20px;" alt="Responsive image">
    </a>
    <div class="btn-show-menu ">
    <div  *ngIf="getIsLogged()" >
      <button id="accountMenu" mat-button [matMenuTriggerFor]="mobileAccountMenu">
        <mat-icon class="account-icon hand color-white">account_circle</mat-icon>
      </button>
      <mat-menu backdropClass="menu-mobile"  #mobileAccountMenu="matMenu">
        <button mat-menu-item id="user-profile-button" class="item-menu-mobile" name="editUserProfile"
          (click)='redirectToPage($event)'>Mi perfil</button>
        <button mat-menu-item id="change-password-button" class="item-menu-mobile" name="changePassword"
          (click)='redirectToPage($event)'>Cambiar contraseña</button>
        <button mat-menu-item id="user-participation-button" class="item-menu-mobile" name="userParticipation"
          (click)='redirectToPage($event)'>Mis participaciones</button>
        <button *ngIf="isUserPaymentModesFeatureEnable()" mat-menu-item id="credit-card-button" class="item-menu-mobile" name="paymentModes"
          (click)='redirectToPage($event)'>Mis Formas de pago</button>
        <button mat-menu-item *ngIf="isAnAccountManager()" id="account-manager-button" class="item-menu-mobile" 
                    (click)='redirectAsAccountManager()'>Gestor de rifa</button>
        <button mat-menu-item *ngIf="isASeller()" id="seller-button" class="item-menu-mobile"
          (click)='redirectAsSeller()'>Vendedor</button>
        <button mat-menu-item id="logout-button" class="item-menu-mobile" name="logout"
          (click)='signout()'>Cerrar sesión</button>
      </mat-menu>
    </div>
    <div >
      <button id="mobileOptionsMenu" mat-button [matMenuTriggerFor]="mobileMenu"><span class="hamburger-box ">
          <span id="menu-button" class="hamburger-inner"></span>
        </span></button>
      <mat-menu  backdropClass="menu-mobile" #mobileMenu="matMenu">
        <button mat-menu-item id="home-button" class="item-menu-mobile" name=""
          (click)='redirectToPage($event)'>Inicio</button>
        <button mat-menu-item id="campaigns-button" class="item-menu-mobile" name="campaigns"
          (click)='redirectToPage($event)' *ngIf="laRifamosContext.showCampaignsOption">Promociones! <span class="badge badge-danger">Nuevo</span></button>
        <button mat-menu-item id="how-it-work-button" class="item-menu-mobile" name="howitwork"
          (click)='redirectToPage($event)'>¿Cómo funciona?</button>
        <button mat-menu-item id="raffle-simulator-button" *ngIf="isRaffleSimulatorEnable()" class="item-menu-mobile" name="raffle-proposal"
          (click)='redirectToPage($event)'>Quiero rifar!</button>
        <button mat-menu-item id="faqs-button" class="item-menu-mobile" name="faqs"
          (click)='redirectToPage($event)'>Preguntas frecuentes</button>
        <button mat-menu-item id="about-us-button" class="item-menu-mobile" name="aboutus"
          (click)='redirectToPage($event)'>Sobre nosotros</button>
        <button mat-menu-item id="contact-button" class="item-menu-mobile" name="contact"
          (click)='redirectToPage($event)'>Contacto</button>
        <button id="signup-button" *ngIf="!isSigninPage() && !isSignupPage() && !getIsLogged()" mat-menu-item
          class="item-menu-mobile" name="signup" (click)='redirectToPage($event)'>Crear cuenta</button>
        <button id="signin-button" *ngIf="!isSigninPage() && !isSignupPage() && !getIsLogged()" mat-menu-item
          class="item-menu-mobile" name="signin" (click)='redirectToPage($event)'>Ingresar</button>
      </mat-menu>
    </div>
  </div>
    
  </div>  
</header>