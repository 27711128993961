
<footer class="footer bg-light m-t-2">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="footer_nav_container d-flex flex-sm-row flex-column align-items-center justify-content-lg-start justify-content-center text-center">
          <ul class="footer_nav s-text8">
            <li id="footer-address-{{country}}">{{footerAddress()}}</li>
          </ul>
        </div>
        <div class="sponsorTable" *ngIf="hasSponsors()">
          <label class="sponsorChild" style="padding-right: 20px;">Apoyan:</label>
          <div class="footer_nav_container d-flex flex-sm-row flex-column align-items-center justify-content-lg-start justify-content-center text-center sponsorChild">
            <ul class="footer_nav s-text8">
              <li>
                  <div class="row" style="padding-top: 20px;">
                      <ng-container *ngFor="let sponsor of footerInfo.sponsors; index as i">
                        <div class="col col-4">
                          <li>
                              <a id="sponsor-link-{{i}}-{{country}}" href="{{sponsor.url}}" target="_blank">
                              <img id="sponsor-image-{{i}}-{{country}}" src="{{sponsor.logo}}"
                                  style="width:100%;filter: grayscale(100%);"/>
                              </a>
                          </li>
                        </div>
                      </ng-container>
                  </div>
              </li>
            </ul>
          </div>
        </div>
        
      </div>
      <div class="col-lg-6" style="margin-top: 60px;" *ngIf="hasSocialNetworks()">
        <div class="footer_social d-flex flex-row align-items-center justify-content-lg-end justify-content-center">
          <ul>
            <ng-container *ngFor="let socialNet of footerInfo.socialNetworks; index as i">
                <li><a id="social-network-{{i}}-{{country}}" href="{{socialNet.url}}" target="_blank"><i id="social-network-logo-{{i}}-{{country}}" class="{{socialNet.logo}}" aria-hidden="true"></i></a></li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="footer_nav_container">
          <div class="cr t-center s-text8 p-t-20">© {{currentYear}} LaRifamos, Inc. </div>
        </div>
      </div>
    </div>
  </div>
</footer>
