import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  url;

  constructor(private http: HttpClient) {
    this.url = environment.APIEndpoint + '/raffle-service/currency';
  }

  getCurrencies() {
    return this.http.get(this.url)
      .pipe(
        tap(
          data => {
          }
        ));
  }


  getDisplayableCurrencies(): any
  {
    return this.http.get(this.url+'/displayable')
      .pipe(
        tap(
          data => {
          }
        ));
  }
}
