import { HttpErrorResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LaRifamosErrorHandler {
 
 

  errorMessages: Array<string> = new Array();
  errorMessagesInFields = new Map();
  hasErrorMessage: boolean = (this.errorMessages.length + this.errorMessagesInFields.size > 0);
  hasErrorMessageInFields = false;
  errorResponse: HttpErrorResponse;

  public fillErrorData(message: string, errorField ?: string): void {
    if (errorField !== undefined) {
      this.errorMessagesInFields.set(errorField, message);
      this.hasErrorMessageInFields = true;
    } else {
      this.errorMessages.push(message);
      this.hasErrorMessage = true;
    }
  }
  public hasErrorMessageInField(): boolean {
      return (this.errorMessagesInFields.size) > 0;
  }

  public hasError(field: string): boolean {
    return this.errorMessagesInFields.get(field) != undefined;
  }

  public hasErrorMessages(): boolean {
    return (this.errorMessages.length) > 0;
  }

  public reset(): void {
    this.errorMessagesInFields.clear();
    this.errorMessages = new Array();
    this.hasErrorMessageInFields = false;
    this.errorResponse = undefined;
  }

  fillErrorResponse(error: HttpErrorResponse) {
    this.errorResponse = error;
  }

  errorStatus() : number{
    if (this.errorResponse)
      return this.errorResponse.status;
    return 0;
  }

  showErrorResponse(message?: string) {
    if (this.errorResponse.error)
      this.fillErrorData(this.errorResponse.error);
    else
      this.fillErrorData(message ? message : 'Ocurrió un error al realizar la operación');
  }

  
}
