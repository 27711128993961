import {Component, OnInit} from '@angular/core';
import { FooterInformationService } from 'src/app/services/footer-information.service';
import { FooterInformation } from 'src/app/entities/footer-information';
import {LaRifamosErrorHandler} from 'src/app/handler/laRifamosErrorHandler';
import { retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {
    currentYear: number;
    footerInfo : FooterInformation;
    country:string
    constructor(private footerService: FooterInformationService,
    private errorHandler: LaRifamosErrorHandler) {
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit() {
    this.getFooterInformation();
    this.country = environment.country;
  }

  getFooterInformation() {
    const resp = this.footerService.getFooterInformation();
    resp.subscribe(
        (response) => {
            this.footerInfo = response as FooterInformation; 
        },
        errorResponse => {
            this.errorHandler.showErrorResponse();
          })
  }


  footerAddress(): string {
    if (this.footerInfo && this.footerInfo.address)
      return this.footerInfo.address;
    return '';
  }

  hasSponsors(): boolean {
    return this.footerInfo != null && this.footerInfo.sponsors && this.footerInfo.sponsors.length > 0;
  }

  hasSocialNetworks(): boolean {
    return  this.footerInfo != null &&  this.footerInfo.socialNetworks && this.footerInfo.socialNetworks.length > 0;
  }
}
