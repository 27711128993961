import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LaRifamosContext } from '../context/laRifamosContext';
import { tap } from 'rxjs/operators';
import { SwPush } from '@angular/service-worker';
import { isPlatformBrowser } from '@angular/common';
import { WindowService } from './window.service';

@Injectable({
  providedIn: 'root'
})
export class PushService {
  url;
  subscribed = false;

  constructor(private http: HttpClient, private authService: AuthService, private context: LaRifamosContext, private swPush: SwPush,
    private windowService: WindowService) {
    this.url = environment.APIEndpoint + '/push-service/subscriptions/';
    this.swPush.subscription.subscribe(sub => this.subscribed = sub !== null);
  }

  public unsubscribe(): void {
    this.swPush.unsubscribe();
  }

  public subscribeForNotifications(): void {
    this.askForSubscription();
    this.subscribeForNotificationActions();
  }

  public isSubscribed() {
    return this.subscribed;
  }

  private subscribe(subscription: any): any {
    let subscribeCommand = this.createSubscribeCommand(JSON.parse(JSON.stringify(subscription)));
    let header = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: header };
    return this.http.post<SubscribeCommand>(this.url, subscribeCommand, options)
      .pipe(tap(
        data => {
        }
      ));
  }

  private createSubscribeCommand(subscription: any) {
    let result = new SubscribeCommand();
    let user = this.authService.getLoggedInUser();
    if (user != null) {
      result.document = user.document;
      result.userId = user.id;
      result.firstName = user.firstName;
      result.lastName = user.lastName;
      result.username = user.username;
    }
    result.endpoint = subscription.endpoint;
    result.keys = new SubscribeCommandKeys(subscription['keys']['p256dh'], subscription['keys']['auth']);
    return result;
  }

  private askForSubscription() {
    if (this.swPush.isEnabled)
      this.swPush.requestSubscription({
        serverPublicKey: environment.subscription.publicKey
      }).then(sub => {
        this.subscribe(sub).subscribe();
        console.log(JSON.stringify(sub));
      }).catch((_) => console.log);
  }

  private subscribeForNotificationActions() {
    if (this.swPush.isEnabled)
      this.swPush.notificationClicks.subscribe(
        ({ action, notification }) => {
          this.windowService.open(notification['data']['onActionClick'][action]['url'], notification['title']);
        });
  }
}



export class SubscribeCommand {
  public userId: String;
  public firstName: String;
  public lastName: String;
  public username: String;
  public document: String;
  public endpoint: String;
  public keys: SubscribeCommandKeys;
}

export class SubscribeCommandKeys {
  constructor(public p256dh: String,
    public auth: String) { }
}
