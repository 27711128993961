import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { WalletActivationRequest } from '../entities/wallet-activation-request';
import { CampaignService } from './campaign.service';
import { Wallet } from '../entities/wallet/wallet';
import { RaffleEvaluationDTO } from '../entities/campaigns/raffle-evaluation-dto';
import { Raffle } from '../entities/raffle';
import { ActiveWalletDetail } from '../entities/campaigns/active-wallet-detail';
import { PurchaseProjectionRequest } from '../entities/campaigns/purchase-projection-request';
import { PurchaseProjectionResult } from '../entities/purchase-projection-result';
import { Observable, of } from 'rxjs';
import { PurchaseEvaluationRequest } from '../entities/campaigns/purchase-evaluation-request';
import { EvaluationResult } from '../entities/campaigns/campaign-evaluation-result';

@Injectable()
export class WalletService {
  url;
  friendCodeKey = 'fiendCode';
  friendCodeTime = 'friendCodelTime';
  

  constructor(private http: HttpClient, private authService: AuthService, private campaignService: CampaignService) {
    this.url = environment.APIEndpoint + '/wallet-service/wallets';
  }

  public isAvailableToUseCoupons(state: string): boolean {
    return this.authService.isUserLoggedIn() && (state.includes('STARTED') ||  state.includes('APPROVED'));
  }

  

  purchaseEvaluationRequest(wallet: Wallet, raffle: Raffle, ticketQuantitySelected: number): PurchaseEvaluationRequest{
    const activeWalletDetails: Array<ActiveWalletDetail> = this.activeWalletDetails(wallet);

          
    let purchaseEvaluationRequest  = new PurchaseEvaluationRequest();
    purchaseEvaluationRequest.activeWalletDetails = activeWalletDetails;
    purchaseEvaluationRequest.raffleId = raffle.id;
    purchaseEvaluationRequest.unitAmount = raffle.pricePerNumber;
    purchaseEvaluationRequest.quantity = ticketQuantitySelected;
    purchaseEvaluationRequest.pin = this.campaignService.getPurchasePin();
    return purchaseEvaluationRequest;
  }


  activeWalletDetails(wallet: Wallet): Array<ActiveWalletDetail> {
    return wallet.coupons.map(function(coupon){
        let activeWalletDetail = new ActiveWalletDetail();
        activeWalletDetail.campaignId = coupon.campaignId;
        activeWalletDetail.quantity = 1;
        activeWalletDetail.unitValue = coupon.value;
        activeWalletDetail.walletRegistrationType = coupon.valueType;
        activeWalletDetail.uuid = coupon.id;
        return activeWalletDetail;
    });
}

  execute(activationRequest: WalletActivationRequest): any {
    let executeWalletActivationUrl = this.url + '/activation';
    return this.http.post(executeWalletActivationUrl, activationRequest)
      .pipe(
        tap(
          data => {
          }
        ));
  }

  find(userId: string, document: string): any {
    let findWalletUrl = this.url + '/' + userId + '/' + document;
   return this.http.get(findWalletUrl)
      .pipe(
        tap(
          data => {
          }
        ));
  }

  getFriendCode(): string{
    if (this.hasFriendCode() && this.friendCodeIsAlive()) {
        return  localStorage.getItem(this.friendCodeKey);
    } else {
        this.reset();
        return "";
    }
  }

  friendCodeIsAlive(): boolean{
      var currentTime = new Date();
      const storedFriendTime = localStorage.getItem(this.friendCodeTime);
      if (storedFriendTime != undefined  && storedFriendTime !== null && storedFriendTime !== '') {
          const millisOfLastUpdate = Number.parseInt(storedFriendTime);
          const differenceTime = currentTime.getTime() - millisOfLastUpdate;
          //max 5 hours
          const maxDifference = 5 * 60 * 60 * 1000;
          return differenceTime < maxDifference;
      }
      return false;
  }  

  hasFriendCode(): boolean{
      const storedFriendCode = localStorage.getItem(this.friendCodeKey);
      return storedFriendCode != undefined  && storedFriendCode !== null && storedFriendCode !== '' ;
  }

  setFriendCode(friendCode: string) {
      localStorage.setItem(this.friendCodeKey, friendCode);
      var currentTime = new Date();
      localStorage.setItem(this.friendCodeTime, currentTime.getTime()+"");
  }

  reset() {
      localStorage.removeItem(this.friendCodeKey);
      localStorage.removeItem(this.friendCodeTime);
  }

}
