import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LaRifamosContext } from '../context/laRifamosContext';
import { tap } from 'rxjs/operators';
import { Seller } from '../components/admin/seller/seller.model';
import { AddUserCommand } from '../components/admin/entities/add-user-command';
import { AddUserDetailCommand } from '../components/admin/entities/add-user-detail-command';
import { RaffleNumberCommand } from '../components/admin/entities/raffle-number-command';
import { ReceiptCancelRequest } from '../components/admin/entities/receipt-cancel-request';


@Injectable({
  providedIn: 'root'
})
export class RaffleSellerService {
  
  
 
  url;

  constructor(private http: HttpClient,  private context: LaRifamosContext) {
    this.url = environment.APIEndpoint + '/raffle-service/raffle-seller/';
  }

  receiptsReport( receiptState: string, raffleId: string, page: number, pageLimit: number): any {
    const reportUrl = this.url + 'receipts/' + receiptState + '/raffle/' + raffleId;
    let queryParams = new HttpParams();
    queryParams = queryParams.append("pageNumber", page);
    queryParams = queryParams.append("pageLimit", pageLimit);
    return this.http.get(reportUrl, {
      params: queryParams
    }).pipe();
  }

  public downloadReceiptReport( receiptState: string, raffleId: string) {
    const reportUrl = this.url + 'receipts/' + receiptState + '/raffle/' + raffleId +'/download';
    return this.http.get(reportUrl, { observe: 'response', responseType: 'blob' } );
  }

  clientsReportDownload(raffleId: string) {
    const reportUrl = this.url + 'clients/raffle/' + raffleId + '/download';
    return this.http.get(reportUrl, {  observe: 'response', responseType: 'blob' });
  }

  public findByUserId( userId: string): any {
    return this.http.get(this.url + userId)
      .pipe(tap(
        data => {
        }
      ));
  }

  public findSellersByRaffle(raffleId: string): any {
    let serviceUrl = this.url + 'raffleId/' + raffleId;
    return this.http.get(serviceUrl)
      .pipe(tap(
        data => {
        }
      ));
  }

  public findDetailSellersByRaffle(raffleId: string): any {
    let serviceUrl = this.url + 'detail/raffleId/' + raffleId;
    return this.http.get(serviceUrl)
      .pipe(tap(
        data => {
        }
      ));
  }

  public downloadSellerReport(raffleId: string): any {
    let serviceUrl = this.url + 'download/raffleId/' + raffleId;
    return this.http.get(serviceUrl, { observe: 'response', responseType: 'blob' });
  }

  public findRaffleSellers(userId: string): any {
   return this.http.get(this.url + userId + '/raffles')
      .pipe(tap(
        data => {
        }
      ));
  }

  public addSeller(raffleId: string, seller: Seller): any {
    let users: Array<AddUserDetailCommand> = [new AddUserDetailCommand(seller)];
    let addUserCommand = new AddUserCommand(raffleId, users);
    
    return this.http.post(this.url, addUserCommand)
      .pipe(
        tap(
          data => {
          }));
  }

  public importSeller( raffleId: string, sellers: Array<Seller>): any {
    let users = new Array<AddUserDetailCommand>();
    for (let index = 0; index < sellers.length; index++) {
      users.push(new AddUserDetailCommand(sellers[index]));
    }
    let addUserCommand = new AddUserCommand(raffleId, users);
    return this.http.post(this.url, addUserCommand)
      .pipe(
        tap(
          data => {
          }));
  }

  public assignNumbers(raffleNumberCommand: RaffleNumberCommand) {
    return this.http.post(this.url + 'asignNumbers', raffleNumberCommand)
      .pipe(
        tap(
          data => {
          }));
  }

  public requestNumber(raffleNumberCommand: RaffleNumberCommand) {
    return this.http.post(this.url + 'requestNumbers', raffleNumberCommand)
      .pipe(
        tap(
          data => {
          }));
  }

  public returnNumber(raffleNumberCommand: RaffleNumberCommand) {
    
    return this.http.post(this.url + 'returnNumbers', raffleNumberCommand)
      .pipe(
        tap(
          data => {
          }));
  }
  public updateSeller(raffleId: string, seller: Seller): any { }
  public deleteSeller(raffleId: string, sellerId: string): any { }


  findTaskByStateAndRaffle(state: string, raffleId: string): any {
    return this.http.get(this.url + 'tasks/state/' + state + '/raffle/' + raffleId)
      .pipe(tap(
        data => {
        }
      ));
  }


  clientsReport(raffleId: string, page: number, pageLimit: number): any {
    const reportUrl = this.url + 'clients/raffle/' + raffleId;
    let queryParams = new HttpParams();
    queryParams = queryParams.append("pageNumber", page);
    queryParams = queryParams.append("pageLimit", pageLimit);
    return this.http.get(reportUrl, {
      params: queryParams
    }).pipe();
  }


  cancelReceipt(cancelRequest: ReceiptCancelRequest) {
    return this.http.post(this.url + 'receipt/cancel', {
      'purchaseId': cancelRequest.purchaseId,
      'reason': cancelRequest.reason
    })
      .pipe(
        tap(
          data => {
          }
        ));
  }

  
}