import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse} from '@angular/common/http';

import {tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import { ReportableQuotationRequest } from '../components/simulator/entity/reportable-quotation-request';


@Injectable()
export class SimulatorService {

    APIEndpoint = environment.APIEndpoint;
    apiUrl = this.APIEndpoint + '/larifamos-service/quotations/';

    constructor(private http: HttpClient, private authService: AuthService) {
    }

    simulate(quotation: any): any {
        const header = new HttpHeaders({
            'Content-Type': 'application/json'});
        const options = {headers: header};
        const url = this.apiUrl + 'simulate';
      return this.http.post(url,
        this.buildQuotationJson(quotation),
      options)
        .pipe(
          tap(
            data => {
            }
          ));
    }

  doQuotation(quotation: any): any {
    const header = new HttpHeaders({
      'Content-Type': 'application/json'});
    const options = {headers: header};
    const url = this.apiUrl + 'doQuotation';
    return this.http.post(url,
      this.buildQuotationJson(quotation),
      options)
      .pipe(
        tap(
          data => {
          }
        ));
  }

  invokeReportableQuotation(reportableQuotationRequest: ReportableQuotationRequest): any{
    const header = new HttpHeaders({
      'Content-Type': 'application/json'});
    const options = {headers: header};
    const url = this.apiUrl + 'doReportableQuotation';
    let quotation = reportableQuotationRequest.quotationRequest;
    return this.http.post(url, 
      {
        'quotationRequest': this.buildQuotationJson(quotation),
        'userFirstName': reportableQuotationRequest.userFirstName,
        'userLastName': reportableQuotationRequest.userLastName,
        'userEmail': reportableQuotationRequest.userEmail,
        'userPhoneNumber': reportableQuotationRequest.userPhoneNumber
      }
     , options)
      .pipe(
        tap(
          data => {
          }
        ));
  }

  buildQuotationJson(quotation: any):any{
    return  {
      'type': quotation.type,
      'raffleName': quotation.raffleName,
      'raffleDescription': quotation.raffleDescription,
      'currency': quotation.currency,
      'itemAmount': quotation.itemAmount,
      'itemType': quotation.itemType,
      'minBeneficiaryProfit': quotation.minBeneficiaryProfit,
      'maxBeneficiaryProfit': quotation.maxBeneficiaryProfit,
      'prizeState': quotation.prizeState,
      'prizeHasWarranty': quotation.prizeHasWarranty,
      'promotionWeeks': 0,
      'promotionAmountPerWeek': 0,
      'ticketValue': quotation.ticketValue.valueOf(),
      'rentAnOffice': quotation.rentAnOffice
    };
  }
  
  warrantyValues(): any[] {
    return [{ 'id': 0, 'code': 'true', 'description': 'Si' },
      { 'id': 1, 'code': 'false' , 'description': 'No' }
    ];
  }
  statesValues(): any[] {
    return [{ 'id': 0, 'code': 'NEW', 'description': 'Nuevo' },
      { 'id': 1, 'code': 'USAGE' , 'description': 'Usado' }
    ];
  }
  categoryValues(): any[] {
    return [{ 'id': 0, 'code': 'CAR', 'description': 'Automóviles' },
      { 'id': 1, 'code': 'OTHER' , 'description': 'Otros' }
    ];
  }
  ticketValueDefault(): any[] {
    return [{ 'id': 0, 'code': '100', 'description': '$100' },
      { 'id': 1, 'code': '200' , 'description': '$200' },
      { 'id': 2, 'code': '250' , 'description': '$250' },
      { 'id': 3, 'code': '300' , 'description': '$300' },
      { 'id': 4, 'code': '500' , 'description': '$500' }
    ];
  }
  getCategoryValue(code: string): string {
    return this.categoryValues().find((category) => category.code.match(code)).description;
  }
  getStateValue(code: string): string {
    return this.statesValues().find((state) => state.code.match(code)).description;
  }
  getWarrantyValue(code: boolean): string {
    return this.warrantyValues().find((state) => state.code.match(code)).description;
  }
  }
