import {EventEmitter, Injectable, Output} from '@angular/core';
import {User} from '../entities/user';
import { LaRifamosContext } from '../context/laRifamosContext';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {tap} from 'rxjs/operators';
import { RaffleUserRole } from '../enums/raffle-user-role.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { RaffleSellerService } from './raffle-seller.service';
import { RaffleAccountManagerService } from './raffle-account-manager.service';
import { Observable } from 'rxjs';


@Injectable()
export class AuthService {
  
  
  
  userKey = 'currentUser';
  laRifamosTokenKey = 'laRifamosToken';
  laRifamosRefreshTokenKey = 'laRifamosRefreshToken';
  laRifamosTokenExpirationMillisKey = 'laRifamosTokenExpirationMillis';
  accountManagerKey = 'accountManager';
  sellerKey = 'seller';
  apiUrl: string;
  currentRaffleUserRole: RaffleUserRole;
  definedRole: boolean = false; 
  

  @Output() isLoggedIn: EventEmitter<any> = new EventEmitter<any>();

  constructor(private context: LaRifamosContext, private http: HttpClient, private route: ActivatedRoute,
    public raffleAccountManagerService: RaffleAccountManagerService, 
    public raffleSellerService: RaffleSellerService, public router: Router) {
    this.apiUrl = environment.APIEndpoint + '/auth-service/auth';
  }

  roleIsDefined(): boolean{
    return this.definedRole;
  }
  
  setCurrentRaffleUserRole(raffleUserRole: RaffleUserRole){
    this.currentRaffleUserRole = raffleUserRole;
    this.definedRole = true;
  }

  getCurrentRaffleUserRole(){
    return this.currentRaffleUserRole;
  }
  getLoggedInUser(): User{
    return this.isUserLoggedIn() ? JSON.parse(localStorage.getItem(this.userKey)) : null;
  }

  setLoggedInUser(user: User) {
    localStorage.setItem(this.userKey, JSON.stringify(user));
    this.context.registerUserOnContext(user);
    this.isLoggedIn.emit(true);
    this.defineRole();
  }

  defineRole(){
    const raffleAccountManagerResponse = this.raffleAccountManagerService.findByUserId(this.getLoggedInUser().id);
    raffleAccountManagerResponse.subscribe(successResponse => {
      this.setUserAsAnAccountManager();
      this.findAsSeller();
    },
    error => {
      this.findAsSeller();
    });

  }
  findAsSeller() {
    const raffleSellerResponse = this.raffleSellerService.findByUserId(this.getLoggedInUser().id);
    raffleSellerResponse.subscribe(successResponse => {
      this.setUserAsASeller();
    });
  }

  getLoggedInUserToken() {
    return this.isUserLoggedIn() ? localStorage.getItem(this.laRifamosTokenKey) : '';
  }


  getLoggedInUserRefreshToken() {
    return this.isUserLoggedIn() ? localStorage.getItem(this.laRifamosRefreshTokenKey) : '';
  }

  hasRefeshToken(){
    return localStorage.getItem(this.laRifamosRefreshTokenKey) != null;
  }

  processLoginHeader(headers: HttpHeaders) {
    this.setLoggedInUserToken(headers.get('authorization'));
    this.setRefreshToken(headers.get('refreshToken'));
    
  }

  setLoggedInUserToken(token: string){
    localStorage.setItem(this.laRifamosTokenKey, token);
  }

  setRefreshToken(token: string){
    localStorage.setItem(this.laRifamosRefreshTokenKey, token);
  }

  signout() {
    localStorage.removeItem(this.laRifamosTokenKey);
    localStorage.removeItem(this.laRifamosRefreshTokenKey);
    localStorage.removeItem(this.userKey);
    localStorage.removeItem(this.accountManagerKey);
    localStorage.removeItem(this.sellerKey);
    this.isLoggedIn.emit(false);
    this.context.cleanContext();
    this.currentRaffleUserRole = undefined;
    this.definedRole = false;
  }


  isAnAccountManager(): boolean {
    const accountManager = localStorage.getItem(this.accountManagerKey);
    return accountManager != undefined  && accountManager == 'true' ;
  }

  isASeller(): boolean{
    const seller = localStorage.getItem(this.sellerKey);
    return seller != undefined  && seller == 'true' ;
  }

  setUserAsAnAccountManager(){
    localStorage.setItem(this.accountManagerKey, 'true');
    this.definedRole = true;
  }

  setUserAsASeller(){
    localStorage.setItem(this.sellerKey, 'true');
    this.definedRole = true;
  }

  isUserLoggedIn() {
    const user = localStorage.getItem(this.userKey);
    return user != undefined  && user !== null && user !== '' ;
  }

  getUserByToken(token: string) {
    const url = this.apiUrl + '/me/laRifamos/' + token;
    return this.http.get(url).pipe(tap(
        data => {
        }
      ));
  }
  getEmitter(){
    return this.isLoggedIn;
  }


refreshToken() : Observable<any>{
    const url = environment.APIEndpoint + '/auth-service/refreshToken';
    const header = new HttpHeaders({
    'Content-Type': 'application/json',
    'refreshToken': this.getLoggedInUserRefreshToken()});
    
    return this.http.post(url,{} , {observe: 'response', headers: header }).pipe(
      tap(
        data => {
          this.processLoginHeader(data.headers);
        }, 
        error => {
          
        }
      ));
  } 


  
}
