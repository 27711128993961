import {Ticket} from './ticket';
import {Payment} from './payment/payment';
import {WalletUse} from './wallet/wallet-use';
import { SellerDTO } from './seller';
import { Owner } from './owner';
import {Discount} from "./campaigns/discount";
export class PurchaseRequest {
    
    ownerId: string;
    owner: Owner;
    raffleId: string;
    tickets: Array<Ticket>;
    payments: Array<Payment>;
    discounts: Array<WalletUse>;
    directDiscounts: Array<Discount>;
    subtotal: number = 0;
    discountTotal: number = 0;
    total: number=0;
    referredCode: string;
    channel: string;
    seller :SellerDTO;
    pin:string;

    constructor(){
        this.tickets = new Array<Ticket>();
        this.payments = new Array<Payment>();
        this.discounts = new Array<WalletUse>();
        this.directDiscounts = new Array<Discount>();
    }

    addTicket(ticket: Ticket){
        this.tickets.push(ticket);
        this.removeAllDiscount();
        this.calculateTotal();
    }

    addTickets(tickets: Ticket[]) {
        tickets.forEach(ticket => this.tickets.push(ticket));
        this.removeAllDiscount();
        this.calculateTotal();
    }

    calculateTotal() {
        this.total = 0;
        this.discountTotal = 0;

        this.tickets.forEach(ticket => {
            this.total += ticket.amount;
        });
        
        this.subtotal = this.total;

        this.discounts.forEach(discount => {
            this.total -= discount.unitAmount; 
            this.discountTotal += discount.unitAmount * discount.availableQuantity;
        });

        this.directDiscounts.forEach(discount => {
            this.total -= discount.amount;
            this.discountTotal += discount.amount;
        });

        if (this.payments.length > 0){
            this.payments[0].amount = this.total;
        }
    }

    removeTicket(ticket: Ticket){
        const indexToRemove = this.tickets.indexOf(this.tickets.find(tick => tick.number === ticket.number));
        if (indexToRemove !== -1)
            this.tickets.splice(indexToRemove, 1);
        this.removeAllDiscount();

    }

    removeAllDiscount(){
        while ( 0 != this.discounts.length){
            const currentDiscuount = this.discounts[0];
            this.discounts.splice(0,1);
        }
        this.calculateTotal();
    }

    ticketIsSelected(ticket: Ticket){
        return this.tickets.find(tick => tick.number === ticket.number) != undefined;
    }

    addDiscount(discount: WalletUse){
        this.discounts.forEach((walletUse) => {
            if (walletUse.uuid === discount.uuid) {
                if (walletUse.unitAmount != discount.unitAmount) {
                    this.removeDiscount(discount);
                } else {
                    return;
                }
            }
        });           
        this.discounts.push(discount);
        this.calculateTotal();
    }

    setDiscounts(walletUses: WalletUse[]) {
        this.removeAllDiscount();
        walletUses.forEach(walletUse => {
            this.addDiscount(walletUse);
        });        
    }

    setDirectDiscounts(discounts:Discount[]) {
        this.directDiscounts = discounts;
        this.calculateTotal();
    }

    removeDiscount(discount: WalletUse){
        const indexToRemove = this.discounts.indexOf(this.discounts.find(walletUse => walletUse.uuid === discount.uuid));
        if (indexToRemove !== -1)
            this.discounts.splice(indexToRemove, 1);
        
        this.calculateTotal();
    }


    addPayment(payment: Payment){
        this.payments.push(payment);
    }

    removePayment(payment: Payment){
        if (payment != undefined) {
        const indexToRemove = this.payments.indexOf(this.payments.find(pay => pay.paymentModeId === payment.paymentModeId));
        if (indexToRemove !== -1)
            this.payments.splice(indexToRemove, 1);
        }
        
    }

    couponIsSelected(coupon: WalletUse){
        return coupon != undefined && this.discounts.find(walletUse => walletUse.uuid === coupon.uuid) != undefined;
    }

    public update(){
        this.calculateTotal();
    }

    cleanAllRandomTicketSelected() {
        let randomTickets = this.tickets.filter(ticket => ticket.randomSelection == true);
        for (let randomTicket of randomTickets){
            this.removeTicket(randomTicket);
        }
    }



}