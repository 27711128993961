import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';

@Injectable()
export class TermsAndConditionsService {
  APIEndpoint = environment.APIEndpoint;
  apiUrl = this.APIEndpoint + '/larifamos-service/';

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getTermnsAndConditions(): any {
    const url = this.apiUrl + 'terms_and_conditions';

    const auth = this.authService.getLoggedInUserToken();
    return this.http.get(url)
      .pipe(
        tap(
          data => {
          },
          error => console.log("Error getting terms and conditions ")
        ));
  }

}
