<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<div *ngIf="isInMarketplaceMode()">
    <app-header></app-header>
</div>
<div id="app" class="h-100 app" [ngClass]="settings.theme" [class.main-toolbar-fixed]="settings.mainToolbarFixed"
    [dir]="(settings.rtl) ? 'rtl' : 'ltr'">
    <main class="d-flex flex-column wrapper {{isInMarketplaceMode() ? 'margin-marketplace' : 'margin-admin' }}">
           
        <div style="min-height: 1500px;">
            <router-outlet (activate)="onActivate($event)"></router-outlet>
        </div>

        <div fxLayout="column" fxLayoutAlign="space-between stretch" *ngIf="needShowCookiesMessage"
            class="cookies-banner" id="cookies">
            <img class="cookie-image" src="../assets/img/cookie.svg" alt="Cookie">
            <button mat-icon-button (click)="deactiveCookiesView()" class="cookie-close-button">
                <mat-icon>close</mat-icon>
            </button>
            <p class="cookie-p">LaRifamos utiliza cookies para su funcionamiento, para mantener la sesión y personalizar
                la experiencia del usuario, así como para obtener estadísticas anónimas de uso de la web.</p>
            <button class="cookieButton" id="acceptCookiesButton" (click)="acceptCookies()">Aceptar</button>
        </div>
        <div *ngIf="needShowCookiesMessage" class="backgroundCookiesBanner" id="backgroundCookiesBanner"></div>

        <app-footer></app-footer>

    </main>
    
</div>