import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { LaRifamosContext } from './context/laRifamosContext';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TermsAndConditionsService } from './services/terms-and-conditions-service';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { TermsAndConditionsModule } from './components/terms-and-conditions/terms-and-conditions.module';
import { CanActivateWrongMail } from './guards/can-activate-wrong-mail';
import { HasWrongEmailModule } from './components/overlay/has-wrong-email/has-wrong-email.module';
import { SimulatorService } from './services/simulator.service';
import { CurrencyService } from './services/currency.service';
import { CampaignService } from './services/campaign.service';
import { ChannelService } from './services/channel.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { LayoutModule } from '@angular/cdk/layout';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LaRifamosErrorHandler } from './handler/laRifamosErrorHandler';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LangModule } from './components/shared/lang/lang.module';
import { AppSettings } from './app.settings';
import { WindowService } from './services/window.service';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RaffleAccountManagerService } from './services/raffle-account-manager.service';
import { WalletService } from './services/wallet.service';
import { JWTInterceptor } from './jwt-interceptor';



export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, environment.translateUrl + '/assets/i18n/', '.json');
}



@NgModule({
  declarations: [
    AppComponent, HeaderComponent, FooterComponent
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HammerModule,
    OverlayModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TermsAndConditionsModule,
    HasWrongEmailModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: true }),
    MatIconModule,
    MatMenuModule,
    LayoutModule,
    LangModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },

    }),
    MatProgressSpinnerModule],
  providers: [SimulatorService, CurrencyService, AuthService, ChannelService, 
    LaRifamosContext, TermsAndConditionsService, CanActivateWrongMail, CampaignService, 
    TranslateService, AppSettings, LaRifamosErrorHandler,WindowService, RaffleAccountManagerService, WalletService,
    {provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
