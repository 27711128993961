import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CampaignEvaluationRequest } from '../entities/campaigns/campaing-evaluation-request';
import { AuthService } from './auth.service';
import { PurchaseProjectionRequest } from 'src/app/entities/campaigns/purchase-projection-request';

@Injectable()
export class CampaignService {
  url;
  purchasePin: string = 'purchasePin';
  purchasePinTime = 'purchasePinTime';


  constructor(private http: HttpClient, private authService: AuthService) {
    this.url = environment.APIEndpoint + '/campaign-service/campaigns';
  }

  actives(): any {
    let activesCampaignsUrl = this.url + '/actives/'+environment.appName;
    return this.http.get(activesCampaignsUrl, {})
      .pipe(
        tap(
          data => {
          }
        ));
  }

  evaluate(request: CampaignEvaluationRequest): any {
    let evaluateUrl = this.url + '/evaluate';
    return this.http.post(evaluateUrl, request)
      .pipe(
        tap(
          data => {
          }
        ));
  }

  purchaseProjection(request: PurchaseProjectionRequest): any {
    request.pin = this.getPurchasePin();
    let purchaseProjectionUrl = this.url + '/evaluate/project-purchase';
    return this.http.post(purchaseProjectionUrl, request)
      .pipe(
        tap(
          data => {
          }
        ));
  }


  getPurchasePin(): string {
    if (this.hasPurchasePin() && this.purchasePinIsAlive()) {
        return  localStorage.getItem(this.purchasePin);
    } else {
        this.reset();
        return "";
    }
  }

  purchasePinIsAlive(): boolean{
      var currentTime = new Date();
      const storedFriendTime = localStorage.getItem(this.purchasePinTime);
      if (storedFriendTime != undefined  && storedFriendTime !== null && storedFriendTime !== '') {
          const millisOfLastUpdate = Number.parseInt(storedFriendTime);
          const differenceTime = currentTime.getTime() - millisOfLastUpdate;
          //max 5 hours
          const maxDifference = 5 * 60 * 60 * 1000;
          return differenceTime < maxDifference;
      }
      return false;
  }  

  hasPurchasePin(): boolean{
      const storedFriendCode = localStorage.getItem(this.purchasePin);
      return storedFriendCode != undefined  && storedFriendCode !== null && storedFriendCode !== '' ;
  }

  setPurchasePin(purchasePin: string) {
      localStorage.setItem(this.purchasePin, purchasePin);
      var currentTime = new Date();
      localStorage.setItem(this.purchasePinTime, currentTime.getTime()+"");
  }

  reset() {
      localStorage.removeItem(this.purchasePin);
      localStorage.removeItem(this.purchasePinTime);
  }

}
