import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class WindowService {
  
  constructor(private titleService: Title){}


  title(title: string) {
    this.titleService.setTitle(title);
  }
  
  
  
  isInMobile() {
    return navigator.userAgent.toLowerCase().indexOf('mobile') > 0 ||
      navigator.userAgent.toLowerCase().indexOf('iphone') > 0 ||
      navigator.userAgent.toLowerCase().indexOf('android') > 0 ||
      navigator.userAgent.toLowerCase().indexOf('nexus') > 0;
  }
  
  pushAcceptCookiesField() {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({ 'event': 'acceptCookies' });
  }
    
  scrollBy(scrollA: number, scrollB: number) {
    window.scrollTo(scrollA, scrollB);
  }


  pageYoffset(): number{
    return window.pageYOffset;
  }
  
    indexOfUrl(url: string): number{
    return window.location.href.indexOf(url);
  }

  locationUrl(url: string) {
    window.location.href = url;
  }

  scrollTop() {
    window.scroll(0,0);
  }

  getWidth(): number {
    return window.innerWidth;
  }

  open(url: any, title: any){
    window.open(url, title);
  }

  scrollToElementId(elementId: string){
    document.getElementById(elementId).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  downloadFile(fileContent: Blob, fileName: string) {
    let a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(fileContent);
    a.click();
  }
}