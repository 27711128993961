import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import {AuthService} from '../services/auth.service';


@Injectable()
export class CanActivateWrongMail implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate() {
    if (this.authService.isUserLoggedIn()) {
      const user = this.authService.getLoggedInUser();
      if (user.hasWrongEmail) {
        this.router.navigate(['/editUserProfile']);
        return false;
      }
    }
    return true;
  }
}
