import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import * as $ from 'jquery';
import { LaRifamosContext } from 'src/app/context/laRifamosContext';
import { RaffleAccountManagerService } from 'src/app/services/raffle-account-manager.service';
import { RaffleSellerService } from 'src/app/services/raffle-seller.service';
import { RaffleUserRole } from 'src/app/enums/raffle-user-role.enum';
import { WindowService } from 'src/app/services/window.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  

  constructor(private router: Router, private authService: AuthService, public laRifamosContext: LaRifamosContext,
    public raffleAccountManagerService: RaffleAccountManagerService, 
    public raffleSellerService: RaffleSellerService,
    private windowService: WindowService) {

  }

  ngOnInit() {
  }

  isInMobile() {
    return this.windowService.isInMobile();
  }

  isSigninPage() {
    return this.windowService.indexOfUrl('/signin') > 0;
  }

  isSignupPage() {
    return this.windowService.indexOfUrl('/signup') > 0;
  }

  getCurrentUser() {
    return this.authService.getLoggedInUser();
  }

  getIsLogged() {
    return this.authService.isUserLoggedIn();
  }
  getIsProviderLocal() {
    return this.authService.getLoggedInUser().provider === 'local';
  }

  signout(): void {
    this.authService.signout();
    this.router.navigate(['/']);
  }

  signoutMobile() {
    this.signout();
  }

  redirectToPage(event) {
    this.router.navigate(['/' + event.target.name]);
  }

  redirectAsAccountManager() {
    this.laRifamosContext.marketPlaceMode = false;
    this.authService.setCurrentRaffleUserRole(RaffleUserRole.ACCOUNT_MANAGER);
    this.router.navigate(['/raffleUser/', 'accountManager']);
  }

  redirectAsSeller() {
    this.laRifamosContext.marketPlaceMode = false;
    this.authService.setCurrentRaffleUserRole(RaffleUserRole.SELLER);
    this.router.navigate(['/raffleUser/', 'seller']);
  }

  isASeller(): boolean {
    return this.authService.isASeller();
  }

  isAnAccountManager(): boolean {
    return this.authService.isAnAccountManager();
  }


  isRaffleSimulatorEnable(){
    return environment.raffleSimulatorEnable;
  }


  isUserPaymentModesFeatureEnable():boolean {
    return environment.userPaymentModesFeatureEnable;
  }
}

