export const environment = {
    production: false,
    country: "UY",
    APIEndpoint: 'https://apigatewaydemo.larifamos.com',
    translateUrl: "https://demo.larifamos.com",
    baseUrl: "https://demo.larifamos.com",
    domain: 'demo.larifamos.com',
    protocol: 'https',
    appName:"laRifamos",
    raffleSimulatorEnable: true,
    socialAuthRedirectUri: 'https://larifamos.com/auth',
    mercadoPago : { accessToken: 'TEST-7710988171901184-072018-c9ab2a1810b5074d3d101998c5ea287e-451443319',
                    publicKey: 'TEST-08d4f903-be22-448a-a15d-fed3db88c287',
                    urlApiV1: 'https://api.mercadopago.com/v1/'},
    subscription: { publicKey : 'BMqgs9tsmREgeOPXbFESrnJfkmEQWG3fHy9E_DMY0NcfK9F6zpmOVoZGmyOoEUwrpThtNcEvEHbchCd5d5lZ2MI'},
    userPaymentModesFeatureEnable: true
};