import {Injectable} from '@angular/core';

@Injectable()
export class ChannelService {
    channelKey = 'channel';
    channelTime = 'channelTime';

    constructor() {}

    getChannelId(): string{
        if (this.hasChannel() && this.channelIsAlive()) {
            return  localStorage.getItem(this.channelKey);
        } else {
            this.reset();
            const organicChannel = "organic";
            this.setChannel(organicChannel);
            return organicChannel;
        }
    }

    channelIsAlive(): boolean{
        var currentTime = new Date();
        const channelTime = localStorage.getItem(this.channelTime);
        if (channelTime != undefined  && channelTime !== null && channelTime !== '') {
            const millisOfLastUpdate = Number.parseInt(channelTime);
            const differenceTime = currentTime.getTime() - millisOfLastUpdate;
            //max 5 hours
            const maxDifference = 5 * 60 * 60 * 1000;
            if (differenceTime < maxDifference){
                return true;
            }
        }
        return false;
    }  
    
    hasChannel(): boolean{
        const channel = localStorage.getItem(this.channelKey);
        return channel != undefined  && channel !== null && channel !== '' ;
    }
    
    setChannel(channel: string) {
        localStorage.setItem(this.channelKey, channel);
        var currentTime = new Date();
        localStorage.setItem(this.channelTime, currentTime.getTime()+"");
    }

    reset() {
        localStorage.removeItem(this.channelKey);
        localStorage.removeItem(this.channelTime);
    }
    
}